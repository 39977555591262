import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "text-secondary col flex items-center justify-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_carousel_slide = _resolveComponent("q-carousel-slide")!
  const _component_Card = _resolveComponent("Card")!
  const _component_q_carousel = _resolveComponent("q-carousel")!

  return (_openBlock(), _createElementBlock("div", {
    class: "row items-center",
    style: _normalizeStyle(_ctx.$q.screen.lt.sm ? '' : 'margin: 0px -70px')
  }, [
    _createVNode(_component_q_carousel, {
      modelValue: _ctx.slide,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.slide) = $event)),
      arrows: "",
      infinite: "",
      "control-color": "black",
      class: "col q-px-xl",
      height: "300px"
    }, {
      default: _withCtx(() => [
        (!_ctx.news.length)
          ? (_openBlock(), _createBlock(_component_q_carousel_slide, {
              name: 1,
              key: 1,
              class: "col row"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.$t('Home.News.NoNews')), 1)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.keys([...Array(_ctx.news.length > 3 ? _ctx.news.length - (_ctx.tableLength - 1) : 1)]).map(Number), (index) => {
          return (_openBlock(), _createBlock(_component_q_carousel_slide, {
            name: index + 1,
            key: index,
            class: "col row"
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.news.slice(index, index + _ctx.tableLength), (n) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: n.id,
                  class: _normalizeClass([_ctx.$q.screen.lt.sm ? 'col-12' : _ctx.$q.screen.lt.md ? 'col-6' : 'col-4', "q-pa-sm"])
                }, [
                  _createVNode(_component_Card, {
                    title: n.title,
                    desc: n.description,
                    path: ('/news/' + n.id),
                    selectable: false,
                    url: _ctx.baseApi + n.banner
                  }, null, 8, ["title", "desc", "path", "url"])
                ], 2))
              }), 128))
            ]),
            _: 2
          }, 1032, ["name"]))
        }), 128))
      ]),
      _: 1
    }, 8, ["modelValue"])
  ], 4))
}